import React, { useEffect, useState } from "react"
import { fetchSIByGroupId } from "../../../api/StrategicImperatives"
import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import { toast } from "../../../utils"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
export const StrategicImperativesDropDown = ({ groupId, value, onChange, error }) => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let [allSis, setAllSis] = useState([])
    let [loading, setLoading] = useState(false)
    let { t } = useTranslation()
    useEffect(() => {
        if (groupId) {
            setAllSis([])
            getSIByGroupId(groupId)
        }
        else {
            setAllSis([])
        }
    }, [groupId ,tokenStatus])

    useEffect(() => {

    }, [groupId,tokenStatus])

    async function getSIByGroupId(id) {
        try {
            setLoading(true)
            let res = await fetchSIByGroupId(id)
            setLoading(false)
            setAllSis(res.data.response)
        }
        catch (err) {
            setLoading(false)
            toast.error(err?.response?.data?.error)
        }
    }

    return (
        <div>
            <Autocomplete
                value={value ? allSis.length !== 0 ? allSis.find(e => e._id === value) : null : null}
                options={allSis}
                sx={{ width: "300px", backgroundColor: "white" }}
                loading={loading}
                getOptionLabel={(option) => option.name
                }
                renderInput={(params) => (
                    <TextField
                        error={error}
                        {...params}
                        label={t("Strategic Imperatives")}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                onChange={(e, val) => {
                    onChange(val && val._id)
                }}

                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option._id}>
                            {option.name
                            }
                        </li>
                    );
                }}
            />
        </div>
    )
}