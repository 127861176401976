import { fetchCategoriesByGroupId } from "./category";

export const BASE_URL = process.env.BASE_URL;
export const API = {
  login: "/login",
  autologin: "/auto-login",
  // categories apis 
  getAllCategories: "/getAllCategories",
  fetchCategoryById: "/fetchCategoryById/",
  editCategory: "/editCategory",
  createCategory: "/createCategory",
  editCategories: "/editCategories",
  deleteCategories: "/deleteCategories",
  deleteCategory: "/deleteCategory/",
  fetchCategoriesByGroupId: "/fetchCategoriesByGroupId/",
  // question apis 
  allQuestions: "/getAllQuestions",
  createQuestion: "/createQuestion",
  createBulkQuestions: "/createBulkQuestions",
  editQuestion: "/editQuestion",
  deleteQuestions: "/deleteQuestions",
  fetchQuestionById: "/fetchQuestionById/",
  fetchQuestionsByCategories: "/fetchQuestionsByCategories",
  fetchQuestionsByCompetencies: "/fetchQuestionsByCompetencies",
  copyQuestions: "/copyQuestions",
  // Sub Group apis
  createSubGroup: "/createSubGroup",
  fetchSubGroupsByGroupId: "/fetchSubGroupsByGroupId/",
  fetchSubGroupById: "/fetchSubGroupById/",
  editSubGroup: "/editSubGroup",
  getAllSubGroups: "/getAllSubGroups",
  deleteSubGroup: "/deleteSubGroup/",
  // SI apis
  createSI: "/createSI",
  fetchSIByGroupId: "/fetchSIByGroupId/",
  deleteSI: "/deleteSI/",
  fetchSIById: "/fetchSIById/",
  editSI: "/editSI",
  fetchGroups: "/fetchGroups/",
  // KPI apis
  fetchKPIByGroupId: "/fetchKPIByGroupId/",
  createKPI: "/createKPI",
  fetchKPIById: "/fetchKPIById/",
  deleteKPI: "/deleteKPI/",
  editKPI: "/editKPI",
  fetchKPI: "/fetchKPI",
  // assessment apis
  createAssessment: "/createAssessment",
  fetchAssessmentById: "/fetchAssessmentById/",
  editAssessment: "/editAssessment",
  deleteAssessment: "/deleteAssessment/",
  fetchAssessments: "/fetchAssessments",
  getSurveyFormById: "/getSurveyFormById/",
  saveSurveyResponse: "/saveSurveyResponse",
  sendReminderEmails: "/sendReminderEmails/",
  fetchUserAssessments: "/fetchUserAssessments",
  validateJobRoleAssessment: "/validateJobRoleAssessment/",
  // reports apis
  generateAssessmentReport: "/generateAssessmentReport/",
  generateOrganizationalSurveyAssessmentReport: "/generateOrganizationalSurveyAssessmentReport/",
  generateJobroleSurveyAssessmentReport: "/generateJobroleSurveyAssessmentReport/",
  generateIndividualSurveyAssessmentReport: "/generateIndividualSurveyAssessmentReport/",
  generateComparisonAssessmentReport: "/generateComparisonAssessmentReport",
  //users apis
  fetchTPUsersByGroup: "/fetchTPUsersByGroup/",
  fetchTPGroup: "/fetchTPGroupById/",
  // (home) dashboard apis
  generateSuperAdminDashboard: "/generateSuperAdminDashboard",
  generateGroupAdminDashboard: "/generateGroupAdminDashboard/",

  // competency apis 
  createCompetency: "/createCompetency",
  getAllCompetencies: "/getAllCompetencies",
  editCompetencies: "/editCompetencies",
  editCompetency: "/editCompetency",
  assignCompetency: "/assignCompetency",
  deleteCompetencies: "/deleteCompetencies",
  fetchCompetenciesByGroupId: "/fetchCompetenciesByGroupId/",
  fetchCompetencyById: "/fetchCompetencyById/",
  deleteCompetency: "/deleteCompetency/",
  getCompetenciesByJobRole: "/fetchCompetenciesByJobRole/",
  fetchCompetenciesByCategories: "/fetchCompetenciesByCategories",
  getCompetenciesByCategory: "/getCompetenciesByCategory",

  fetchJobRolesByGroupId: "/fetchJobRolesByGroupId/",
  createJobRole: "/createJobRole",
  fetchJobRoleById: "/fetchJobRoleById/",
  editJobRole: "/editJobRole",
  deleteJobRole: "/deleteJobRole/",
  fetchTPJobRoles: "/fetchTPJobRoles/"

};

