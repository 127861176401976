import React, { useEffect, useState } from "react"
import style from "../../../assets/css/components/kpi&sicommon.module.css"
import { Autocomplete, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { CustomInput } from "../../Inputs/CustomInput"
import { STRAT_MEASUREMENT_TYPE, STRAT_PRIORITY, TYPE_OF_KPI } from "../../../constants/constants"
import { CustomRadio } from "../../Inputs/CustomRadio"
import { CustomDatePicker } from "../../Inputs/CustomDatePicker"
import { getallgroups } from "../../../store/Slices/GroupSlice"
import { StrategicImperativesDropDown } from "./StrategicImperativesDropDown"
import { useTranslation } from "react-i18next"

export const KPISIForm = ({ data, type, mode, onSubmit, onClose, groupDisable }) => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    // type can be "KPI" or "SI"
    let { t } = useTranslation()
    let [formData, setFormData] = useState({ ...data })
    let allGroups = useSelector(e => e.groupData.allGroups)
    let dispatch = useDispatch()
    let [validation, setValidation] = useState({
        name: false,
        groupId: false,
        kpi: false,
        siId: false,
        startDate: false,
        endDate: false
    })

    useEffect(() => {
        if (allGroups.length === 0) {
            dispatch(getallgroups())
        }
    }, [dispatch, allGroups.length, tokenStatus])


    function formSubmit() {
        let isFormValid = checkValidation()
        if (isFormValid) {
            onSubmit(formData)
        }
    }

    function checkValidation() {
        let validFields = []
        if (type === "KPI") {
            validFields = ["name", "groupId", "siId"]
        }
        else {
            validFields = ["name", "groupId", "startDate", "endDate"]
        }
        let obj = {}
        let error = []

        validFields.forEach((res) => {
            if (!formData[res] || formData[res]?.toString()?.trim() === "") {
                obj[res] = true
                error.push(res)
            }

        })
        setValidation(obj)
        return error.length === 0 ? true : false
    }


    function resetErrorFields() {
        setValidation({
            name: false,
            groupId: false,
            kpi: false,
            siId: false,
            startDate: false,
            endDate: false
        })
    }

    return (
        <div className={style.KPISI_create_form_con}>

            <div>
                <Autocomplete
                    value={formData.groupId ? allGroups.length !== 0 ? allGroups.find(e => e.id === formData.groupId) : null : null}
                    options={allGroups}
                    sx={{ width: "300px" }}
                    getOptionLabel={(option) => option.name}
                    disabled={groupDisable}

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Group Name")}
                            variant="outlined"

                            error={validation.groupId}
                        />
                    )}
                    onChange={(e, val) => {
                        let obj = {}
                        obj.groupId = val && val.id
                        if (type === "KPI") {
                            obj.siId = null
                        }

                        setFormData({ ...formData, ...obj })
                        resetErrorFields()
                    }}

                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                />
            </div>

            <div className={style.strat_dark_area}>

                <div>
                    <TextField
                        error={validation.name}
                        value={formData?.name}
                        onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value })
                            resetErrorFields()
                        }}
                        className={style.bg_white}
                        label={type === "SI" ? t("Strategic Imperatives") : t("KPI.Key Performance Indicators")}
                        fullWidth />
                </div>
                {type === "KPI" ?
                    <div className={style.radio_inputs_con}>
                        <div>
                            <StrategicImperativesDropDown
                                error={validation.siId}
                                groupId={formData.groupId}
                                value={formData?.siId}
                                onChange={(e) => {
                                    setFormData({ ...formData, siId: e })
                                    resetErrorFields()
                                }}
                            />
                        </div>
                        {/* <div>
                            <div className={style.label}>Type of KPI:</div>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={formData?.type}
                                    row
                                    className={style.radio_text}
                                    name="type"
                                    onChange={(e) => {
                                        setFormData({ ...formData, type: e.target.value })
                                        resetErrorFields()
                                    }}
                                >
                                    {TYPE_OF_KPI.map((res, key) => {
                                        return (
                                            <CustomRadio label={res} key={key} value={res} />
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </div> */}
                    </div>
                    :
                    <>
                        <div className={style.radio_inputs_con}>
                            <div>
                                <div className={style.label}>{t("Priority")}:</div>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={formData?.priority}
                                        row
                                        className={style.radio_text}
                                        name="priority"
                                        onChange={(e) => {
                                            setFormData({ ...formData, priority: e.target.value })
                                            resetErrorFields()
                                        }}
                                    >
                                        {STRAT_PRIORITY.map((res, key) => {
                                            return (
                                                <CustomRadio label={res} key={key} value={res} />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>
                                <div className={style.label}>{t("SI.Measurement Type")}:</div>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={formData?.measurementType}
                                        className={style.radio_text}
                                        row
                                        name="measurementType"
                                        onChange={(e) => {
                                            setFormData({ ...formData, measurementType: e.target.value })
                                            resetErrorFields()
                                        }}
                                    >
                                        {STRAT_MEASUREMENT_TYPE.map((res, key) => {
                                            return (
                                                <FormControlLabel key={key} value={res} control={<Radio sx={{
                                                    '&, &.Mui-checked': {
                                                        color: 'rgba(82, 96, 109, 1)',
                                                    }
                                                }} />} label={res} />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={style.calender_con}>
                            <div>
                                <CustomDatePicker
                                    disablePast error={validation.startDate} label={t("Start Date")} value={formData?.startDate}
                                    onChange={(e) => {
                                        setFormData({ ...formData, startDate: e ? new Date(e.toString()).toISOString() : null })
                                        resetErrorFields()
                                    }} />

                            </div>
                            <div>
                                <CustomDatePicker
                                    minDate={formData.startDate}
                                    disablePast error={validation.endDate} label={t("End Date")} value={formData?.endDate}
                                    onChange={(e) => {
                                        setFormData({ ...formData, endDate: e ? new Date(e).toISOString() : null })
                                        resetErrorFields()
                                    }
                                    } />

                            </div>
                            <div></div>
                        </div>
                    </>
                }

            </div>

            <br />
            <div className="end">
                <button className="btn-primary" onClick={formSubmit}>{t(mode)}</button>&nbsp;&nbsp;&nbsp;
                <button className="btn-secondary" onClick={onClose}>{t("Cancel")}</button>
            </div>
        </div >
    )
}