import React, { Fragment, useEffect, useState } from 'react'
import { BreadCrum } from '../../components/Common/BreadCrum'
import style from "../../assets/css/views/reports.module.css"
import { ASSESSMENT_TYPES, CREATE_ASSESSMENT_TYPE, REPORTS_PRIORITY, REPORTS_TABS } from '../../constants/constants'
import { CustomDatePicker } from '../../components/Inputs/CustomDatePicker'
import { ProgressCard } from '../../components/Common/Reports/ProgressCard'
import { TableHeader } from '../../components/Common/TableContainer'
import { SearchInput } from '../../components/Inputs/SearchInput'
import { CustomPagination } from '../../components/Common/CustomPagination'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material'
import { CustomCheckbox } from '../../components/Inputs/CustomCheckbox'
import { CustomProgressLine } from '../../components/Common/CustomProgressLine'
import { useLocation, useParams } from 'react-router'
import { CustomSelect } from '../../components/Inputs/CustomSelect'
import { fetchAssessments } from '../../api/Assessment'
import { generateOrganizationalSurveyAssessmentReport, generateJobroleSurveyAssessmentReport } from '../../api/Reports'
import { toast } from '../../utils'
import { searchDataWithKey } from '../../utils/search'
import { getSubGroupByGroupId } from '../../api/Group'
import { DownloadButton } from '../../components/Buttons/DownloadButton'
import { DownloadPdf } from '../../components/Common/DownloadPdf'
import { NoTableData } from '../../components/Common/NoTableData'
import { useSelector } from 'react-redux'


export const ReportsDetail = () => {
  const tokenStatus = useSelector((state) => state.tokenStatus);
  const { assessmentType } = useParams()
  let [activeType, setActiveType] = useState("Baseline")
  let { t } = useTranslation()
  let [groupName, setGroupName] = useState("")
  let [page, setPage] = useState(1)
  let [allData, setAllData] = useState({
    siData: [],
    kpiData: []
  })

  let [searchValue, setSearchValue] = useState("")

  let [dataLoader, setDataLoader] = useState(true)
  let [isError, setIsError] = useState({
    error: true,
    errorMessage: ""
  })

  let [allAssessmentCycle, setAllAssessmentCycle] = useState([])

  let [reportData, setReportData] = useState({ kpiData: [], siData: [] })

  let [subGroups, setSubGroups] = useState([])
  let [priority, setPriority] = useState("High")
  let [allSubGroups, setAllSubGroups] = useState([])
  let itemPerPage = 20
  let [filterInputValues, setFilterInputValues] = useState({
    assessmentCycle: null
  })
  let [assessmentName, setAssessmentName] = useState(undefined)

  let params = useParams()

  function pageChange(e) {
    setPage(e)
  }
  // useEffect(() => {
  //   const fetchSubgroups = async () => {
  //     try {
  //       let res = await getSubGroupByGroupId(params.id)

  //       setAllSubGroups(res.data.response)
  //     }
  //     catch (err) {
  //       toast.error(err?.response?.data?.error)

  //     }
  //   }
  //   fetchSubgroups()
  // }, [tokenStatus])



  useEffect(() => {
    getAssessmentCycleData()
  }, [activeType, tokenStatus])


  async function getAssessmentCycleData() {
    setIsError({
      error: false,
      errorMessage: ""
    })
    setAllAssessmentCycle([])
    let query = {
      "$and": [
        { type: activeType },
        { assessmentType: assessmentType },
        { status: "Completed" },
        { groupId: Number(params.id) }
      ]
    }
    try {
      let res = await fetchAssessments(query)
      setAllAssessmentCycle(res.data.response)
      setGroupName(res.data.groupName)
      if (res.data.response.length === 0) {
        setFilterInputValues(pre => ({ ...pre, assessmentCycle: null }))
      } else {
        setFilterInputValues(pre => ({ ...pre, assessmentCycle: res.data.response[0] }))
        setAssessmentName(res.data.response[0].name)
      }
      setIsError({
        error: true,
        errorMessage: res.data.response.length !== 0 ? "" : "No Data Found"
      })
      setDataLoader(false)
    }
    catch (err) {
      setDataLoader(false)
      setIsError({
        error: true,
        errorMessage: err?.response?.data?.error
      })
    }
  }

  useEffect(() => {
    if (filterInputValues.assessmentCycle) {
      getReport()
    }
    else {
      setReportData({
        siData: [],
        kpiData: []
      })
      setIsError({
        error: true,
        errorMessage: "No Data Found"
      })
    }
  }, [filterInputValues.assessmentCycle, tokenStatus])

  async function getReport() {
    setIsError({
      error: false,
      errorMessage: ""
    })
    try {
      setDataLoader(true)
      setIsError(pre => ({ ...pre, error: false }))

      if (assessmentType === ASSESSMENT_TYPES.organizational) {
        let res = await generateOrganizationalSurveyAssessmentReport(filterInputValues.assessmentCycle._id)
        setDataLoader(false)
        let formattedData = formatChartData(res.data.response, activeType)

        setReportData(formattedData)
        setAllData(formattedData)
      }
      else {
        // Job Role assessment
        let res = await generateJobroleSurveyAssessmentReport(filterInputValues.assessmentCycle._id)
        setDataLoader(false)
        let formattedData = formatChartData(res.data.response, activeType)

        setReportData(formattedData)
        setAllData(formattedData)
      }

    }
    catch (err) {
      setReportData([])
      setDataLoader(false)
      setIsError({ errorMessage: err?.response?.data?.error, error: true })
    }
  }

  function formatChartData(data, type) {
    let chartData = {
      siData: [],
      kpiData: []
    }


    if (type === "Baseline") {

      switch (assessmentType) {
        // format data in case of organizational assessment type 
        case ASSESSMENT_TYPES.organizational:
          chartData.siData = data?.strategicImperativesData?.map(e => {

            return ({
              name: e.name,
              value1: Math.floor(e.SIScore),
              active: false,
              _id: [e._id]
            })
          })
          chartData.kpiData = data?.questions?.map(e => {
            return ({
              name: e.kpiData?.name,
              value1: Math.floor(e.kpiData?.kpiScore),
              siId: e.kpiData?.siId
            })
          })

          break;
        // format data in case of jobrole assessment type 
        case ASSESSMENT_TYPES.jobRole:
          chartData.siData = data?.categoriesData?.map(e => {
            return ({
              name: e.name,
              value1: Math?.floor(e?.categoryScore) || 0,
              active: false,
              _id: [e._id]
            })
          })
          chartData.kpiData = data?.questions?.map(e => {
            return ({
              name: e.competencyData?.name,
              value1: Math.floor(e.competencyData?.competencyScore),
              siId: e.competencyData?.categoryId
            })
          })
          break;
        // format data in case of individual assessment type 
        // case ASSESSMENT_TYPES.individual:
        //     chartData.siData = []
        //     chartData.kpiData = data?.[0]?.questions?.map(e => {
        //         return ({
        //             name: e.competencyData?.[0].name,
        //             value1: Math.floor(e.competencyData?.[0]?.competencyScore),
        //             siId: null
        //         })
        //     })
        //     break;

        default:
          break;
      }


    }
    // format data in case of pulse survey 
    else {

      switch (assessmentType) {
        // format data in case of organizational assessment type 
        case ASSESSMENT_TYPES.organizational:
          chartData.siData = data.baseLineAssessment?.strategicImperativesData?.map((e, index) => {
            return ({
              name: e.name,
              value1: Math.floor(e.SIScore),
              value2: Math.floor(data.pulseAssessment?.strategicImperativesData[index].SIScore),
              active: false,
              _id: e._id
            })
          })
          chartData.kpiData = data.baseLineAssessment?.questions?.map((e, index) => {
            return ({
              name: e.kpiData.name,
              value1: Math.floor(e.kpiData.kpiScore),
              value2: Math.floor(data.pulseAssessment?.questions[index].kpiData.kpiScore),
              siId: e.kpiData.siId
            })
          })
          break;
        // format data in case of individual assessment type
        // case ASSESSMENT_TYPES.individual:
        //     chartData.siData = []
        //     chartData.kpiData = data.baseLineAssessment?.[0].questions?.map((e, index) => {
        //         return ({
        //             name: e?.competencyData[0]?.name,
        //             value1: Math.floor(e.competencyData[0].competencyScore),
        //             value2: Math.floor(data.pulseAssessment[0].questions[index].competencyData[0].competencyScore),
        //             siId: null
        //         })
        //     })
        //     break;

        default:
          break;
      }

    }
    return chartData

  }

  function search(e) {
    setSearchValue(e.target.value)
    let arr = searchDataWithKey("name", allData.kpiData, e.target.value)
    setReportData(pre => ({ ...pre, kpiData: arr }))
  }

  function siCardClick(clickIndex, siId) {

    setSearchValue("")
    setReportData(pre1 => {
      let pre = { ...pre1 }
      pre.siData = pre?.siData?.map((e, i) => ({ ...e, active: i === clickIndex ? !e.active : false }))

      if (pre.siData[clickIndex].active) {
        // pre.kpiData = [...searchDataWithKey("siId", allData.kpiData, siId)]
        pre.kpiData = allData.kpiData.filter(e => siId.includes(e.siId))
      }
      else {
        pre.kpiData = [...allData.kpiData]
      }

      return ({ ...pre })
    })

  }

  const downloadClickHandler = () => {
    const input = document.getElementById('pdf-content');
    return input;
  };



  let breadCrumData = [
    {
      text: t("Reports"),
      path: `/reports/${assessmentType}`
    },
    {
      text: t(assessmentType),
      // path: `/reports/${assessmentType}`
    },
    {
      text: t("Details"),
    },

  ]

  return (
    <div className={`padding_22_44 ${style.reports_detail_con}`}>
      <BreadCrum dataArr={breadCrumData} />
      <br />

      <br />

      <div id="pdf-content">
        <section className={style.report_filter_con}>
          <div className={style.header}>
            <div className="between">
              <div className="header_4">{groupName}</div>
              <DownloadPdf
                onSubmit={downloadClickHandler}
                fileName={`${filterInputValues.assessmentCycle?.name}`}
              //   onBeforeGenerate={removePdfContentSection}
              >
                <DownloadButton />
              </DownloadPdf>
            </div>
            <div></div>
          </div>
          <div className={style.report_filter} id="pdf-content-section">
            {/* hide tabs  if assessment type is jobrole */}
            {assessmentType !== ASSESSMENT_TYPES.jobRole && (
              <div className={style.tab_con}>
                {CREATE_ASSESSMENT_TYPE.map((res, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => {
                        setActiveType(res);
                        setDataLoader(true);
                      }}
                      className={`${activeType === res ? style.active : ""
                        } pointer`}
                    >
                      {res} Survey
                    </div>
                  );
                })}
              </div>
            )}
            <div className={style.filter_input_con}>
              <div className="w-40">
                <CustomSelect
                  value={
                    filterInputValues.assessmentCycle
                      ? allAssessmentCycle.find(
                        (e) =>
                          e._id === filterInputValues.assessmentCycle._id
                      )
                      : null
                  }
                  options={allAssessmentCycle}
                  optionLabel={"name"}
                  keyLabel={"_id"}
                  onChange={(e, val) => {
                    setFilterInputValues((pre) => ({
                      ...pre,
                      assessmentCycle: val ? val : null,
                    }));
                  }}
                  label={t("Assessment.Assessment Name")}
                  renderOption={"name"}
                />
              </div>
              {/* <div className='w-20'>
                                <FormControl fullWidth>
                                    <InputLabel>{t("Sub Groups")}</InputLabel>
                                    <Select

                                        multiple
                                        value={subGroups}
                                        onChange={(e) => {
                                            {
                                                setSubGroups(e.target.value)
                                            }
                                        }}
                                        input={<OutlinedInput label={t("Sub Groups")} />}
                                        renderValue={(selected) => allSubGroups.filter(e => selected.includes(e._id))?.map(e => e.name).join("/")}
                                    >
                                        {allSubGroups?.map((res) => (
                                            <MenuItem key={res._id} value={res._id}>
                                                <CustomCheckbox className="me-6" checked={subGroups.includes(res._id)} />
                                                <ListItemText primary={res.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div> */}
              {/* <div className='w-20'>

                                <FormControl fullWidth>
                                    <InputLabel>{t("Priority")}</InputLabel>
                                    <Select

                                        value={priority}
                                        label={t("Priority")}
                                        onChange={(e) => setPriority(e.target.value)}
                                    >
                                        {REPORTS_PRIORITY.map((res, key) => {
                                            return <MenuItem key={key} value={res}>{res}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </div> */}
              {/* <div className='w-20'>
                                <CustomDatePicker label={t("Period")} />

                            </div> */}
            </div>
          </div>
        </section>

        {dataLoader ? (
          <div>
            <br />
            <LinearProgress />
          </div>
        ) : (
          <>
            {isError?.error ? (
              <div className="body_1">
                <br />
                <NoTableData text={isError?.errorMessage} />
              </div>
            ) : (
              <section className={style.progress_bar_con}>

                <div className={style.heading}>
                  {assessmentType === ASSESSMENT_TYPES.organizational
                    ? t("Strategic Imperatives")
                    : t("category.categories")}
                  :
                </div>

                <div className={style.card_section_con}>
                  <div className={style.card_viewAll_container}>
                    {reportData?.siData?.map((res, ind) => (
                      <Fragment key={ind}>
                        <ProgressCard
                          showChart={true}
                          onClick={() => siCardClick(ind, res._id)}
                          active={res.active}
                          type={activeType}
                          {...res}
                        />
                      </Fragment>
                    ))}
                  </div>
                </div>
                <br />

                <div className={`subtitle_2`}>
                  {assessmentType === ASSESSMENT_TYPES.organizational
                    ? t("Key performance indicators (KPI)")
                    : t("competency.competencies")}
                  :
                </div>
                <div className={style.kpi_table_con}>
                  <TableHeader>
                    <div className={style.header_con}>
                      <div className={style.left_con}>
                        <SearchInput
                          onChange={search}
                          value={searchValue}
                          placeholder="Search"
                          className="search_inp"
                        />
                      </div>

                      <div className={style.right_con}>
                        <div>0</div>
                        <div>20</div>
                        <div>40</div>
                        <div>60</div>
                        <div>80</div>
                        <div>100</div>
                      </div>
                    </div>
                  </TableHeader>
                  {reportData?.kpiData?.map((res, index) => {
                    return (
                      <div key={index} className={style.table_row_con}>
                        <div className={`${style.left_con} body_1 w-50`}>
                          {res.name}
                        </div>
                        <div className={`${style.right_con} w-50`}>
                          <CustomProgressLine
                            value={res?.value1}
                            variant={"secondary"}
                          />

                          {activeType === "Pulse" ? (
                            <>
                              <br />
                              <CustomProgressLine
                                value={res?.value2}
                                variant={"light_secondary"}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}

                  <div className={style.pagination_con}>
                    <CustomPagination
                      page={page - 1}
                      pageChange={pageChange}
                      count={reportData?.kpiData?.length || 0}
                      perPage={itemPerPage}
                    />
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </div>
    </div>
  );
}
