import React, { useEffect, useRef, useState } from "react"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { loader, toast } from "../../utils"
import { editSI, fetchSIById } from "../../api/StrategicImperatives"
import { useLocation, useNavigate, useParams } from "react-router"
import { KPISIForm } from "../../components/Common/KPI&SICommonComp/KPISIForm"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

export const EditStratImperative = () => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let params = useParams()
    let navigate = useNavigate()
    let [SIData, setSIData] = useState({})
    let [dataFetched, setDataFetched] = useState(false)
    const addMore = new URLSearchParams(useLocation().search).get('addMore');
    let { t } = useTranslation()
    let getsibyid = useRef(getSIById)
    useEffect(() => {
        getsibyid.current()

    }, [getsibyid,tokenStatus])

    async function getSIById() {
        try {
            loader.start()
            let res = await fetchSIById(params.id)
            loader.stop()
            let { name, priority, measurementType, groupId, startDate, endDate, _id } = res.data.response
            setSIData({ name, priority, measurementType, groupId, startDate, endDate, _id })
            setDataFetched(true)
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }
    async function formSubmit(data) {
        try {
            loader.start()
            let res = await editSI(data)
            loader.stop()

            toast.success(res.data.message)
            navigate(`/strategicImperatives/preview/${data.groupId}?${addMore ? "&addMore=true" : ""}`)

        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }
    function formClose() {
        navigate(`/strategicImperatives/preview/${SIData.groupId}`)
    }
    let breadCrumData = [
        {
            text: t("Strategic Imperatives"),
            path: "/strategicImperatives"
        },
        {
            text: t("Edit"),
        },


    ]
    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            {dataFetched ?
                <KPISIForm mode={"Update"} groupDisable={true} onClose={formClose} onSubmit={formSubmit} type={"SI"} data={SIData} />
                : null}
        </div>
    )
}