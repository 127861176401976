import { useLocation, useNavigate, useParams } from "react-router"
import style from "../../assets/css/views/questionBank.module.css"
import React, { useCallback, useEffect, useState } from "react"
import { editQuestion, getQuestionById } from "../../api/Question"
import { QuestionForm } from "../../components/Common/QuestionBank/QuestionForm"
import { loader, toast } from "../../utils"
import { useDispatch, useSelector } from "react-redux"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { useTranslation } from "react-i18next"
import { ASSESSMENT_TYPES } from "../../constants/constants"
import { addCompetencies } from "../../store/Slices/QuestionCompetencySlice"

export const EditQuestion = () => {

    const { assessmentType } = useParams()
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let params = useParams()
    const dispatch = useDispatch()
    let [isDataFetched, setIsDataFetched] = useState(false)
    let [questionData, setQuestionData] = useState({})
    let navigate = useNavigate()
    let questionDetail = useSelector(e => e.questionData.question)
    let { t } = useTranslation()



    async function editQuestionSubmit({ categoryData, createdAt, updatedAt, ...rest }) {
        try {
            loader.start()

            if (rest?.options) {
                let options = addIndexIngToOptions(rest.options)
                rest.options = options
            }

            let res = await editQuestion(rest)
            let newCompetencies = res?.data?.newCompetencies || []
            // store new created competencies to store after question updation
            if (newCompetencies.length > 0) {
                dispatch(addCompetencies(newCompetencies))
            }

            toast.success(res.data.message)
            navigate(`/questionBank/${assessmentType}`);

            loader.stop()
        } catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }

    function addIndexIngToOptions(options) {
        return options.map((option, index) => {
            return { ...option, index: index + 1 }
        })
    }
    const getQuestionByParamsId = useCallback(async () => {
        try {
            // check question in store if it is not in store then get question by question id 
            if (questionDetail.question) {
                setQuestionData(questionDetail)
                setIsDataFetched(true)
                return
            }
            loader.start();
            let res = await getQuestionById(params.id)
            let response = res.data.response

            setQuestionData({ ...response, options: response.options.sort((a, b) => a.index - b.index) })

            loader.stop();
            setIsDataFetched(true)
        } catch (err) {
            loader.stop();
            toast.error(err?.response?.data?.error)

        }
    }, [params.id, questionDetail]);

    useEffect(() => {
        if (tokenStatus) {
            getQuestionByParamsId();
        }

    }, [getQuestionByParamsId, tokenStatus]);
    let breadCrumData = [
        {
            text: t("questionBank.Question Bank"),
            path: `/questionBank/${assessmentType}`
        },
        {
            text: t(assessmentType)
        },
        {
            text: t("Edit Question"),
        },


    ]

    function onCancelClick() {
        navigate(`/questionBank/${assessmentType}`);
    }

    return (
        <div className={`${style.create_question_con} padding_22_44`}>
            {isDataFetched ?
                <>
                    <BreadCrum dataArr={breadCrumData} />
                    <br />
                    <QuestionForm data={questionData} onCancel={onCancelClick} mode="edit"
                        onSubmit={editQuestionSubmit} />
                    < br />
                </>
                : null
            }
        </div>

    )
}