import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react'
import { EditButton } from '../../Buttons/EditButton';
import { DeleteButon } from '../../Buttons/DeleteButton';
import { useNavigate } from 'react-router';
import style from "../../../assets/css/components/jobRole.module.css"
import { deleteJobRole } from '../../../api/jobRoles';
import { loader, toast } from '../../../utils';
import { Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const JobRolePreviewCollapseTab = ({ data, onDeleteJobrole }) => {
    const [collapse, setCollapse] = useState(false)
    let navigate = useNavigate();
    const toggleExpand = () => setCollapse(!collapse)
    const { t } = useTranslation()

    function editData() {
        navigate(`/jobRole/edit/${data._id}`)
    }
    async function deleteData(data) {
        try {
            loader.start()
            let res = await deleteJobRole(data._id)
            toast.success(res?.data?.message)
            onDeleteJobrole(data._id)
        }
        catch (err) {
            toast.error(err?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }

    return (
        <div className={style.jobrole_preview_collapse_row}>
            <div className="between align_center">

                <div className="flex align_center">
                    <div className="expand_btn">
                        {collapse ? <ExpandMore onClick={toggleExpand} /> : <ExpandLess onClick={toggleExpand} />}
                    </div>
                    <div>
                        {data.jobRoleObj.name}
                    </div>
                </div>

                {collapse &&
                    <div>
                        <EditButton
                            confirmation={"Edit"}
                            heading={"Edit"}
                            text={"Are you sure you want to Edit ?"}
                            data={data}
                            onClick={editData}
                        />&nbsp;&nbsp;
                        <DeleteButon
                            confirmation
                            heading={"Delete"}
                            text={"Are your sure your want to delete Job Role ?"}
                            id={data}
                            onDelete={deleteData}

                        />
                    </div>
                }
            </div>
            <Collapse in={collapse} timeout="auto" unmountOnExit>
            {data?.categories.map((e, ind) => <>
                <div className={`${style.jobrole_label} text-secondary`}>{e?.categoryData?.name}:</div>
                <ul className={style.jobrole_list}>
                    {e?.competenciesData.map((comp, ind) => <li key={ind}>{comp?.name}</li>)}
                </ul>
            </>)}
            </Collapse>
        </div>
    )
}
