import { Chip, FormControl, FormControlLabel, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Tooltip } from "@mui/material"
import style from "../../../assets/css/components/questionBank.module.css"
import { DeleteButon } from "../../Buttons/DeleteButton"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllCompetencies } from "../../../store/Slices/QuestionCompetencySlice"
import { CustomCheckbox } from "../../Inputs/CustomCheckbox"
import { EditCompetency } from "../Competency/EditCompetency"
import { toast } from "../../../utils"
import { QUESTION_TYPE, ASSESSMENT_TYPES, QUESTION_TAGS, OPTIONS_TAGS } from "../../../constants/constants"
import { AddCompetency } from "../Competency/AddCompetency"
import { AddCircle } from "@mui/icons-material"
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

export const QuestionForm = ({ data, onSubmit, mode, disabled, onCancel }) => {

    const { assessmentType } = useParams()

    const [allNewCompetencies, setAllNewCompetencies] = useState([])
    const [newSelectedCompetencies, setNewSelectedCompetencies] = useState([])

    let { t } = useTranslation()
    let [questionData, setQuestionData] = useState({ ...data })
    let [invalidFields, setInvalidFields] = useState({
        name: false,
        category: false,
        options: [
            { value: false, weightage: false }
        ]

    })

    function addOption() {
        setQuestionData({ ...questionData, options: [...questionData.options, { name: '', weightage: 0 }] })
    }
    function addQuestionSubmit(e) {
        e.preventDefault()
        let isFormValid = validateForm()
        if (!isFormValid) { return }
        let isWeightageValid = checkWeightageTotal()

        let r = checkOptionsDuplication(questionData.options)
        if (r.isError) {
            setInvalidFields(pre => ({ ...pre, options: r.data }))
            toast.error("All options should be unique !")
            return
        }
        if (isFormValid && isWeightageValid) {
            onSubmit({
                ...questionData,
                assessmentType,
                newCompetencies: allNewCompetencies.filter(comp => newSelectedCompetencies.some(k => k === comp._id)).map(({ name }) => ({ name }))
            })
        }

    }

    function checkOptionsDuplication(optionArr) {
        let error = optionArr.map((res, ind) => {
            let newArr = [...optionArr]
            newArr.splice(ind, 1)
            let isValueAlign = newArr.some(e => e?.name?.toLowerCase()?.trim() == res?.name?.toLowerCase()?.trim())
            return ({ name: isValueAlign, weightage: isValueAlign })
        })
        return ({ isError: error.some(e => e.name), data: error })
    }

    function checkWeightageTotal() {
        let errors = { options: [] }
        if (questionData.questionType === "Weightage") {
            let count = 0;
            questionData.options.forEach(res => count += res.weightage * 1)
            if (count === 100) {
                return true
            }
            else {
                toast.error('Total of weightage should be "100 %"')
                questionData.options.forEach((e, index) => {
                    errors.options[index] = { ...errors.options[index], weightage: true }

                })
                setInvalidFields(errors)
                return false
            }
        }
        else {
            return true
        }

    }
    let inputFieldStyle = {
        '& label.Mui-focused': {
            color: 'rgba(45, 58, 140, 1)',
        },


    }
    let selectInputStyle = {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(45, 58, 140, 1)',
        },
    }

    function validateForm() {
        let errors = { options: [] }
        let error = []
        if (!questionData.question || questionData?.question?.trim() === "") {
            errors.question = true
            error.push("error")
        }
        else {
            errors.question = false
        }

        if (assessmentType !== ASSESSMENT_TYPES.organizational) {
            if (questionData.competencyIds.length === 0 && newSelectedCompetencies.length === 0) {
                errors.category = true
                error.push("error")
            }
            else {
                errors.category = false
            }
        }

        questionData.options.forEach((element, index) => {
            if (!element.name) {
                errors.options[index] = { ...errors.options[index], name: true }
                error.push("error")
            }
            else {
                errors.options[index] = { ...errors.options[index], name: false }

            }
            if (!element.weightage && questionData.questionType === "Weightage") {
                errors.options[index] = { ...errors.options[index], weightage: true }
                error.push("error")
            }
            else {
                errors.options[index] = { ...errors.options[index], weightage: false }

            }
        })
        setInvalidFields(errors)
        if (error.length === 0) {
            return true
        }
        else {
            toast.error("All Fields Are Required !")
            return false
        }
    }


    let dispatch = useDispatch()
    let allCompetencies = useSelector(e => e.questionCompetency.allCompetencies)

    useEffect(() => {
        // call get all category api only when there is no category on store else use category from store
        if (allCompetencies.length === 0) {
            dispatch(getAllCompetencies())
        }
    }, [dispatch, allCompetencies.length])

    // we need to remove selected category from question data (if selected) if we delete that category from edit category modal

    function editcompetencySubmit(data) {
        setQuestionData(prev => ({ ...prev, competencyIds: questionData.competencyIds.filter(e => !e.includes(data)) }));
    }


    function removeOption(index) {
        resetErrorFields()
        let arr = [...questionData.options]
        arr.splice(index, 1)
        setQuestionData({ ...questionData, options: arr })
    }

    function resetErrorFields() {
        setInvalidFields({
            name: false,
            category: false,
            options: [
                { value: false, weightage: false }
            ]
        })
    }

    function addnewCompetency(data) {
        let _id = allNewCompetencies.length + 1
        setAllNewCompetencies(pre => [...pre, { ...data, _id }])
        setNewSelectedCompetencies(pre => [...pre, _id])

    }

    function questionTabClick(tag) {
        setQuestionData(pre => {
            pre.question += tag.value;
            return ({ ...pre })
        })
    }

    function optionTagClick(tag) {
            setQuestionData(pre => {
                pre.options = tag.value;
                return ({ ...pre })
            })
    }


    return (
        <>
            <div className={`${style.question_form}`} >
                <form onSubmit={addQuestionSubmit}>

                    <div className={`${style.form_con} ${mode !== "view" ? style.bordered_form : ''}`}>

                        <div className={style.max_width_70}>
                            <TextField
                                disabled={disabled}
                                fullWidth
                                error={invalidFields.question}
                                label={t("questionBank.Question")}

                                value={questionData.question}
                                onChange={(e) => {
                                    setQuestionData({ ...questionData, question: e.target.value })
                                    resetErrorFields()
                                }}
                            />
                        </div>

                        {QUESTION_TAGS.filter(e => e.assessmentType.includes(assessmentType)).map((res, key) =>
                            <Chip key={key} className={style.question_tag} onClick={() => questionTabClick(res)} label={res.label}></Chip>
                        )
                        }
                        <br />

                        {mode === "view" ? <div className="text_disabled">
                            <div>{t("competency.competency")}</div>
                            <div className={`${style.view_category_box_con} text_disabled`}>
                                {questionData.categoryData.map((res, key) => {
                                    return (
                                        <div key={key}>
                                            {res.name}
                                        </div>
                                    )
                                })}
                            </div>
                        </div> :
                            <>
                                {assessmentType !== ASSESSMENT_TYPES.organizational
                                    &&
                                    <div className="flex align_center">
                                        <div className={style.max_width_70}>
                                            <FormControl className="w-100">
                                                <InputLabel id="demo-multiple-checkbox-label">{t("competency.competency")}</InputLabel>
                                                <Select fullWidth
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    sx={{ ...selectInputStyle }}
                                                    error={invalidFields.category}
                                                    value={[...questionData.competencyIds, ...newSelectedCompetencies]}
                                                    name='admin_array'
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        resetErrorFields()
                                                        setQuestionData({ ...questionData, competencyIds: value.filter(e => allCompetencies.some(c => c._id === e)) })
                                                        setNewSelectedCompetencies(value.filter(e => !allCompetencies.some(c => c._id === e)))
                                                    }
                                                    }
                                                    input={<OutlinedInput label={t("competency.competency")} />}
                                                    renderValue={(selected) => (
                                                        [
                                                            ...allCompetencies.filter(e => questionData?.competencyIds?.indexOf(e._id) !== -1).map(e => e.name),
                                                            ...allNewCompetencies.filter(e => newSelectedCompetencies?.indexOf(e._id) !== -1).map(e => e.name),
                                                        ]

                                                            .join(', ')
                                                    )}
                                                >

                                                    {[...allCompetencies, ...allNewCompetencies]?.length === 0 ? (
                                                        <MenuItem disabled>
                                                            <ListItemText primary="No Competency available" />
                                                        </MenuItem>
                                                    )
                                                        :
                                                        (

                                                            [...allCompetencies, ...allNewCompetencies].map(res => (
                                                                <MenuItem disabled={disabled} key={res._id} value={res._id}>
                                                                    <CustomCheckbox
                                                                        disabled={disabled}
                                                                        checked={
                                                                            questionData.competencyIds.some(e => e === res._id) ||
                                                                            newSelectedCompetencies.some(e => e === res._id)
                                                                        }
                                                                        className="me-6"
                                                                    />

                                                                    <ListItemText primary={res.name} />
                                                                </MenuItem>
                                                            ))
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>


                                        </div>
                                        <div className={style.addCatBtn}>
                                            <AddCompetency
                                                disabled={disabled}
                                                onAddCompetency={addnewCompetency}
                                                allCompetencies={[...allCompetencies, ...allNewCompetencies]}
                                            />&nbsp;&nbsp;
                                            <EditCompetency type='button' onsubmit={editcompetencySubmit} />
                                        </div>
                                    </div>
                                }
                            </>

                        }


                        <br />

                        <div className={mode === "view" ? "text_disabled" : ''}>{t("questionBank.Question Type")}</div>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={questionData.questionType}
                                row
                                className="flex align_center"
                                name="questionType"
                                onChange={(e) => {
                                    setQuestionData({ ...questionData, questionType: e.target.value })
                                    resetErrorFields()
                                }}
                            >
                                {QUESTION_TYPE.map((res, key) => {
                                    return (
                                        <FormControlLabel key={key} value={res} control={<Radio disabled={disabled} sx={{
                                            '&, &.Mui-checked': {
                                                color: 'rgba(82, 96, 109, 1)',
                                            }
                                        }} />} label={res} />
                                    )
                                })}
                                <Tooltip
                                    arrow

                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                padding: "10px",
                                                lineHeight: "15px",
                                                fontSize: "13px",
                                                boxShadow: "0px 0px 5px black",
                                                minWidth: '30rem'
                                            },

                                        },
                                    }}
                                    title="First Option - Most Preferred | Last Option - Least Preferred" placement="right" >
                                    <InfoIcon sx={{ color: 'grey' }} className="pointer" />
                                </Tooltip>


                            </RadioGroup>
                        </FormControl>


                        <div>
                            {OPTIONS_TAGS.map((res, key) => {
                                return (
                                    <Chip onClick={() => optionTagClick(res)} key={key} className={style.option_tag} label={res.label} />
                                )
                            })}
                        </div>

                        {questionData.options.map((opt, key) => {
                            return (
                                <div key={key} className={`${style.options_con} ${style.max_width_70}`}>
                                    <div className={style.items_con}>
                                        <div className={style.left}>
                                            <TextField
                                                error={invalidFields.options[key]?.name}
                                                fullWidth
                                                disabled={disabled}
                                                sx={{
                                                    ...inputFieldStyle
                                                }}
                                                label={`${t("questionBank.Option")} ${key + 1}`}
                                                value={questionData.options[key].name}
                                                onChange={(e) => {
                                                    let arr = { ...questionData };
                                                    let options = arr.options.map((option, index) => ({
                                                        ...option,
                                                        name: index === key ? (e.target.value === "" ? "" : e.target.value) : option.name
                                                    }));
                                                    setQuestionData({ ...arr, options })
                                                    resetErrorFields()
                                                }}
                                            />
                                        </div>
                                        {/* {questionData.questionType === "Weightage" ?
                                            <div className={style.center}>
                                                <FormControl>
                                                    <OutlinedInput
                                                        id="outlined-adornment-weight"
                                                        endAdornment={<InputAdornment position="end">%  </InputAdornment>}
                                                        aria-describedby="outlined-weight-helper-text"
                                                        inputProps={{
                                                            'aria-label': 'weight',
                                                        }}
                                                        error={invalidFields?.options[key]?.weightage}
                                                        disabled={disabled}
                                                        fullWidth
                                                        type="number"
                                                        value={questionData?.options[key]?.weightage}
                                                        sx={{ ...inputFieldStyle }}
                                                        onChange={(e) => {
                                                            if (Number(e.target.value) <= 100) {
                                                                setQuestionData(pre => {
                                                                    let arr = { ...pre }
                                                                    arr.options[key].weightage = Number(e.target.value)
                                                                    return arr
                                                                })
                                                            }
                                                            resetErrorFields()
                                                        }}
                                                    />
                                                </FormControl>
                                            </div> : null} */}
                                        {mode !== "view" ?
                                            <div className={style.right}>
                                                {questionData.options.length > 2 ?
                                                    <DeleteButon disabled={disabled} type='button' onDelete={() => removeOption(key)} /> : null}
                                            </div> : null}
                                    </div>
                                </div>
                            )
                        })}
                        <br />

                        {questionData.options.length < 5 && !disabled ?
                            <>
                                <div className="flex">
                                    <div className={`flex pointer ${style.add_more}`} onClick={addOption}>
                                        <div>
                                            <AddCircle />&nbsp;&nbsp;
                                        </div>
                                        <div>
                                            {t("questionBank.Add More")}
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null}
                    </div>
                    <br />
                    <div className="end">
                        <div>
                            <button type="submit" className="btn-primary">
                                {disabled ? t("Edit") : mode === "edit" ? t("Update") : t("Submit")}
                            </button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button type="button" className="btn-secondary" onClick={onCancel}> {t("Cancel")}</button>
                        </div>
                    </div>
                </form >
            </div >

        </>
    )
}