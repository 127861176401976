export const ADMIN_ROLES = ["administrator", "groupAdmin"]
export const USER_ROLES = ["user"]

// Current supports the following user roles
export const ADMIN_ROLE = "administrator"
export const GROUP_ADMIN_ROLE = "groupAdmin"
export const USER_ROLE = "user"


export const QUESTION_TYPE = ["Rating"]
export const STRAT_PRIORITY = ["Low", "Medium", "High"]
export const STRAT_MEASUREMENT_TYPE = ["Percentage"]
export const TYPE_OF_KPI = ["Organizational", "Individual"]
export const CREATE_ASSESSMENT_TYPE = ["Baseline", "Pulse"]

export const REPORTS_TABS = [
    { label: "Report", value: "Report" },
    { label: "Individual vs Reviewers", value: "Reviewers" },
    { label: "Individual vs Job Role", value: "JobRole" },
    { label: "Individual vs Reviewers vs Job Role", value: "Combined" },
]

export const ASSESSMENT_STATUS = ["Drafts", "In Review", "Ready To Distribute", "Live", "Completed"]

export const ASSESSMENT_STATUS_TYPES = {
    drafts: "Drafts",
    inReview: "In Review",
    readyToDistribute: "Ready To Distribute",
    live: "Live",
    completed: "Completed"
}

export const REPORTS_PRIORITY = ["All", "Low", "Medium", "High"]

export const DATA_CREATE_UPDATE_ACCESS_ROLES = ["administrator"]

export const ASSESSMENT_TYPES = {
    organizational: "Organizational",
    jobRole: "JobRole",
    individual: "Individual"
}

export const SUPPORTED_LANGUAGES = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'ja', name: 'Japanese' },
    { code: 'zh', name: 'Chinese' },
    { code: 'id', name: 'Indonesian' },
];

export const QUESTION_TAGS = [
    { label: "First Name", value: "{first_name}", assessmentType: [ASSESSMENT_TYPES.individual] },
    { label: "Last Name", value: "{last_name}", assessmentType: [ASSESSMENT_TYPES.individual] },
    { label: "Email", value: "{email}", assessmentType: [ASSESSMENT_TYPES.individual] },
    { label: "Job Role", value: "{job_role}", assessmentType: [ASSESSMENT_TYPES.individual, ASSESSMENT_TYPES.jobRole] },
]

export const OPTIONS_TAGS = [
    {
        label: "Satisfaction",
        value: [
            { name: "Very satisfied" },
            { name: "Satisfied" },
            { name: "Neither dissatisfied or satisfied" },
            { name: "Dissatisfied" },
            { name: "Very dissatisfied" }],

    },
    {
        label: "Agreement",
        value: [
            { name: "Strongly agree" },
            { name: "Agree" },
            { name: "Neither agree or disagree" },
            { name: "Disagree" },
            { name: "Strongly disagree" }
        ]
    },
    {
        label: "Frequency",
        value: [
            { name: "Always" },
            { name: "Often" },
            { name: "Sometimes" },
            { name: "Rarely" },
            { name: "Never" }
        ]
    },
    {
        label: "Quality",
        value: [
            { name: "Very good" },
            { name: "Good" },
            { name: "Acceptable" },
            { name: "Poor" },
            { name: "Very poor" }
        ]
    },
    {
        label: "Custom",
        value: [
            { name: "" },
            { name: "" },
            { name: "" },
            { name: "" },
            { name: "" }
        ]
    }
]





